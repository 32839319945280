import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/personal",
        name: "personal",
        children: [
          {
            path: "project/listing",
            name: "projectListing",
            component: () => import("@/views/personal/project/ProjectListing.vue"),
            meta: {
              pageTitle: "Мои проекты",
              breadcrumbs: ["Персональные", "Проекты", "Список"],
            },
          },
          {
            path: "project/details/:id",
            name: "projectDetails",
            component: () => import("@/views/personal/project/ProjectDetails.vue"),
            meta: {
              pageTitle: "Проект",
              breadcrumbs: ["Персональные", "Проекты", "Проект"],
            },
          },
          {
            path: "task/listing",
            name: "taskListing",
            component: () => import("@/views/personal/task/TaskListing.vue"),
            meta: {
              pageTitle: "Мои задачи",
              breadcrumbs: ["Персональные", "Задачи"],
            },
          },
          {
            path: "gantt/listing",
            name: "ganttListing",
            component: () => import("@/views/personal/gantt/GanttListing.vue"),
            meta: {
              pageTitle: "Глобальная диаграмма Гантта",
              breadcrumbs: ["Персональные", "Гантт"],
            },
          },
          {
            path: "kanban/listing",
            name: "kanbanListing",
            component: () => import("@/views/personal/kanban/KanbanListing.vue"),
            meta: {
              pageTitle: "Канбан доска",
              breadcrumbs: ["Персональные", "Канбан"],
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
