enum Actions {
  // action types
  BO_PAGING = "businessObjectActionPaging",
  BO_GET = "businessObjectActionGet",
  BO_POST = "businessObjectActionPost",
  BO_PUT = "businessObjectActionPut",
  BO_DELETE = "businessObjectActionDelete",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
}

enum BaseResources {
  // module paths
  AUTH = "auth",
  PM = "pm",
  DDM = "ddm",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ERROR = "setError",
  SET_PASSWORD = "setPassword",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  BO_INIT_LIST = "businessObjectInit",
  BO_ADD = "businessObjectAdd",
  BO_UPDATE = "businessObjectUpdate",
  BO_DELETE = "businessObjectDelete",
  BO_ERROR = "businessObjectError",
  BO_ERROR_PURGE = "businessObjectErrorPurge",
  BO_HOLDERS_INIT = "businessObjectHoldersInit",
}

export { Actions, Mutations, BaseResources };
