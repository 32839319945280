import ApiService from "@/core/services/ApiService";
import { Actions, BaseResources, Mutations } from "@/store/enums/StoreEnums";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface BusinessObjectsState {
  bo: any;
  error: any;
}

export interface BusinessObjectState {
  type: string;
  businessObjects: any[];
}

@Module
export default class BusinessObjectModule extends VuexModule implements BusinessObjectsState {
  bo = {};
  error = {};
  businessObjectResourceUrl = BaseResources.PM + "/:type/passport";
  businessObjectPagingResourceUrl = BaseResources.PM + "/:type/paging";

  get boHolder() {
    return (type: string) => {
      return this.bo[type] ? this.bo[type] : { businessObjects: [] };
    };
  }

  get errorHolder() {
    return (type: string) => {
      return this.error[type] ? this.error[type] : { error: null };
    };
  }

  get businessObjectList() {
    return (type: string) => {
      return this.boHolder(type).businessObjects;
    };
  }

  get businessObjectById() {
    return (type: string, id: number) => {
      return this.boHolder(type).businessObjects.filter((o) => o.id == id);
    };
  }

  get businessObjectsByFieldValue() {
    return (type: string, field: string, value: string) => {
      return this.boHolder(type).businessObjects.filter((o) => o[field] == value);
    };
  }

  get businessObjectErrorResponse() {
    return (type: string) => this.errorHolder(type).error;
  }

  @Mutation
  [Mutations.BO_HOLDERS_INIT](type) {
    this.bo[type] = this.bo[type] ? this.bo[type] : { businessObjects: [] };
    this.error[type] = this.error[type] ? this.error[type] : { error: null };
  }

  @Mutation
  [Mutations.BO_ERROR]({ type, error }) {
    console.log("m error");
    console.log(error);
    this.error[type] = { error: error };
    console.log("this.error[type]");
    console.log(this.error[type]);
  }

  @Mutation
  [Mutations.BO_ERROR_PURGE](type: string) {
    this.error[type] = { error: null };
  }

  @Mutation
  [Mutations.BO_INIT_LIST]({ type, businessObjects }) {
    this.bo[type] = { businessObjects: businessObjects };
  }

  @Mutation
  [Mutations.BO_ADD]({ type, businessObject }) {
    this.bo[type].businessObjects.push(businessObject);
  }

  @Mutation
  [Mutations.BO_UPDATE]({ type, businessObject }) {
    const index = this.boHolder(type).businessObjects.findIndex((c) => c.id == businessObject.id);
    if (index > -1) {
      this.bo[type].businessObjects[index] = businessObject;
    }
  }

  @Mutation
  [Mutations.BO_DELETE]({ type, id }) {
    const index = this.boHolder(type).businessObjects.findIndex((c) => c.id == id);
    if (index > -1) {
      this.bo[type].businessObjects.slice(index, 1);
    }
  }

  @Action
  [Actions.BO_GET]({ type, id }) {
    this.context.commit(Mutations.BO_HOLDERS_INIT, type);
    this.context.commit(Mutations.BO_ERROR_PURGE, { type: type });
    return ApiService.get(this.businessObjectResourceUrl.replace(":type", type), String(id))
      .then(({ data }) => {
        this.context.commit(Mutations.BO_ADD, { type: type, businessObject: data.result });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.BO_ERROR, { type: type, error: response.data });
      });
  }

  @Action({ rawError: true })
  [Actions.BO_POST]({ type, data }) {
    this.context.commit(Mutations.BO_HOLDERS_INIT, type);
    this.context.commit(Mutations.BO_ERROR_PURGE, { type: type });
    return ApiService.post(this.businessObjectResourceUrl.replace(":type", type), data)
      .then(({ data }) => {
        this.context.commit(Mutations.BO_ADD, { type: type, businessObject: data.result });
      })
      .catch(({ response }) => {
        console.log("response");
        console.log(response);
        this.context.commit(Mutations.BO_ERROR, { type: type, error: response.data });
      });
  }

  @Action
  [Actions.BO_PUT]({ type, id, data }) {
    this.context.commit(Mutations.BO_HOLDERS_INIT, type);
    this.context.commit(Mutations.BO_ERROR_PURGE, { type: type });
    return ApiService.update(this.businessObjectResourceUrl.replace(":type", type), String(id), data)
      .then(({ data }) => {
        this.context.commit(Mutations.BO_ADD, { type: type, businessObject: data.result });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.BO_ERROR, { type: type, error: response.data });
      });
  }

  @Action({ rawError: true })
  [Actions.BO_PAGING]({ type, filter = {} }) {
    this.context.commit(Mutations.BO_HOLDERS_INIT, type);
    this.context.commit(Mutations.BO_ERROR_PURGE, { type: type });
    return ApiService.query(this.businessObjectPagingResourceUrl.replace(":type", type), {
      params: { filter: filter },
    })
      .then(({ data }) => {
        this.context.commit(Mutations.BO_INIT_LIST, { type: type, businessObjects: data.result.items });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.BO_ERROR, { type: type, error: response.data });
      });
  }

  @Action
  [Actions.BO_DELETE]({ type, id }) {
    this.context.commit(Mutations.BO_HOLDERS_INIT, type);
    this.context.commit(Mutations.BO_ERROR_PURGE, { type: type });
    return ApiService.delete(this.businessObjectResourceUrl.replace(":type", type) + "/" + String(id))
      .then(() => {
        this.context.commit(Mutations.BO_DELETE, { type: type, id: id });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.BO_ERROR, { type: type, error: response.data });
      });
  }
}
